import React, {useState} from 'react'
import {useUserSearchQuery} from '../../data/emailTemplate';
import {environment} from '../../config/environment';
import Spinner from '../common/spinner';

export default function EmailUserSelectModel({show,setShow,sendSelectedUserList}) {
    const [selectedUserList,setSelectedUserList]= useState([])
    const [search,setSearch]=useState('')
    const { data:userList, isLoading: fetchloading ,refetch:refetch2}  = useUserSearchQuery({search})
    
    function closeModel(){
        setShow(false)
    }
    function getSearchUsers(value){
        setSearch(value)
        refetch2({search:value})
    }
    function setDefaultImage(event){
        event.target.src="/images/no-profile-image.jpg"
    }

    function isCheckChange(user) {
        var temp=selectedUserList;
        if (temp.filter(u=>u._id===user._id)) {
          temp.push(user);
        } else {
          temp = temp.filter(m => m._id !== user._id);
        }
        setSelectedUserList(temp)

        sendSelectedUserList(temp)
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>Member List</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="col-form-label font-weight-bold">Member Name</label>
                                    <input className="form-control" type="text" placeholder="Enter member name" onChange={(event)=>getSearchUsers(event.target.value)} />

                                <table className="table table-striped table-responsive-md my-3">
                                <thead>
                                    <tr>
                                    <th style={{width: '10%'}} className="text-center"></th>
                                    <th className="text-center">Profile</th>
                                    <th>Member ID</th>
                                    <th>Member Name</th>
                                    <th>Screen Name</th>
                                    <th>Email</th>
                                    <th>Dob</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userList?.data?.map((item,i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center align-middle">
                                                        <input className="cursor" type="checkbox" onChange={()=>isCheckChange(item)} />
                                                    </td>
                                                    <td className="text-center">
                                                        <img className="profile cursor" src={environment.SOCKET_ENDPOINT+item.pp} alt="" onError={setDefaultImage}/>
                                                    </td>
                                                    <td>{item?._id}</td>
                                                    <td>{item?.un}</td>
                                                    <td>{item?.sn}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.dob}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2" onClick={()=>closeModel()}>Save</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
