import React from 'react'
import UserDetailLayout from '../../../layouts/UserDetailLayout'
import {useUserOverviewQuery} from '../../../data/user-overview'
import {mathFloor} from '../../../util/helpers'
import {useFetchStatisticQuery} from '../../../data/statistics.js'
import {useSearchParams} from 'react-router-dom'
import moment from 'moment'

export default function Statistics() {
  
  const [searchParams,setSearchParams]=useSearchParams()

  const { data: statisticsDetail, isLoading: fetchloading ,refetch}  = useFetchStatisticQuery({id:searchParams.get('id')})
  const { data:userDetails, isLoading: fetchloading2 ,refetch2}  = useUserOverviewQuery({id:searchParams.get('id')})

  return (
    <UserDetailLayout>
      <div className="row">
        <div className="col-3"><b>General</b></div>
        <div className="col-9"><b>Value</b></div>
      </div>

      <div className="row">
        <div className="col-3">Id</div>
        <div className="col-9">{statisticsDetail?._id}</div>
      </div>

      <div className="row">
        <div className="col-3">Account Age</div>
        <div className="col-9">{mathFloor(statisticsDetail?.age)}</div>
      </div>

      <div className="row">
        <div className="col-3">Average Daily Loggin Time</div>
        <div className="col-9">{statisticsDetail?.avg_daily_loggin_time_in_sec}</div>
      </div>

      <div className="row">
        <div className="col-3">Average Weekly Loggin Time</div>
        <div className="col-9">{statisticsDetail?.avg_weekly_loggin_time_in_sec}</div>
      </div>

      <div className="row">
        <div className="col-3">Booty Points</div>
        <div className="col-9">{statisticsDetail?.bootyPoints}</div>
      </div>

      <div className="row">
        <div className="col-3">Created Date</div>
        <div className="col-9">{moment(statisticsDetail?.cd).format('YYYY-MM-DD HH:mm:ss:SSS') }</div>
      </div>

      <div className="row">
        <div className="col-3">Count Of Favourite</div>
        <div className="col-9">{statisticsDetail?.countOfFavourite}</div>
      </div>

      <div className="row">
        <div className="col-3">Count Of Friend</div>
        <div className="col-9">{statisticsDetail?.countOfFriend}</div>
      </div>

      <div className="row">
        <div className="col-3">Count Of Ignores</div>
        <div className="col-9">{statisticsDetail?.countOfIgnores}</div>
      </div>

      <div className="row">
        <div className="col-3">Last Login</div>
        <div className="col-9">{moment(statisticsDetail?.ll).format('YYYY-MM-DD HH:mm:ss:SSS')}</div>
      </div>

      <div className="row">
        <div className="col-3">Membership</div>
        <div className="col-9">{statisticsDetail?.membership}</div>
      </div>

      <div className="row">
        <div className="col-3">No Of Logins</div>
        <div className="col-9">{statisticsDetail?.noOfLogins}</div>
      </div>

      <div className="row">
        <div className="col-3">Received Messages</div>
        <div className="col-9">{statisticsDetail?.received_msgs_count}</div>
      </div>

      <div className="row">
        <div className="col-3">Send Messages</div>
        <div className="col-9">{statisticsDetail?.send_msgs_count}</div>
      </div>

      <div className="row mt-3">
        <div className="col-3"><b>Emotes</b></div>
        <div className="col-9"></div>
      </div>

      <div className="row">
        <div className="col-3">Anger</div>
        <div className="col-9">{userDetails?.emotesTracking?.Anger}</div>
      </div>

      <div className="row">
        <div className="col-3">Arms Crossed</div>
        <div className="col-9">{userDetails?.emotesTracking?.ArmsCrossed}</div>
      </div>

      <div className="row">
        <div className="col-3">Attitude</div>
        <div className="col-9">{userDetails?.emotesTracking?.Attitude}</div>
      </div>

      <div className="row">
        <div className="col-3">Blow Kiss</div>
        <div className="col-9">{userDetails?.emotesTracking?.BlowKiss}</div>
      </div>

      <div className="row">
        <div className="col-3">Bow</div>
        <div className="col-9">{userDetails?.emotesTracking?.Bow}</div>
      </div>

      <div className="row">
        <div className="col-3">Frustrated</div>
        <div className="col-9">{userDetails?.emotesTracking?.Frustrated}</div>
      </div>

      <div className="row">
        <div className="col-3">Laugh</div>
        <div className="col-9">{userDetails?.emotesTracking?.Laugh}</div>
      </div>

      <div className="row">
        <div className="col-3">Love You</div>
        <div className="col-9">{userDetails?.emotesTracking?.LoveYou}</div>
      </div>

      <div className="row">
        <div className="col-3">Middle Finger</div>
        <div className="col-9">{userDetails?.emotesTracking?.MiddleFinger}</div>
      </div>

      <div className="row">
        <div className="col-3">Mini Horns</div>
        <div className="col-9">{userDetails?.emotesTracking?.MiniHorns}</div>
      </div>

      <div className="row">
        <div className="col-3">Ponder</div>
        <div className="col-9">{userDetails?.emotesTracking?.Ponder}</div>
      </div>

      <div className="row">
        <div className="col-3">Profess Love</div>
        <div className="col-9">{userDetails?.emotesTracking?.ProfessLove}</div>
      </div>

      <div className="row">
        <div className="col-3">Rofl</div>
        <div className="col-9">{userDetails?.emotesTracking?.Rofl}</div>
      </div>

      <div className="row">
        <div className="col-3">Scoff</div>
        <div className="col-9">{userDetails?.emotesTracking?.Scoff}</div>
      </div>

      <div className="row">
        <div className="col-3">Shake Finger</div>
        <div className="col-9">{userDetails?.emotesTracking?.ShakeFinger}</div>
      </div>

      <div className="row">
        <div className="col-3">Stretch</div>
        <div className="col-9">{userDetails?.emotesTracking?.Stretch}</div>
      </div>

      <div className="row">
        <div className="col-3">Surprised</div>
        <div className="col-9">{userDetails?.emotesTracking?.Surprised}</div>
      </div>

      <div className="row">
        <div className="col-3">The Horns</div>
        <div className="col-9">{userDetails?.emotesTracking?.TheHorns}</div>
      </div>

      <div className="row">
        <div className="col-3">Tongue Mock</div>
        <div className="col-9">{userDetails?.emotesTracking?.TongueMock}</div>
      </div>

      <div className="row">
        <div className="col-3">Upset</div>
        <div className="col-9">{userDetails?.emotesTracking?.Upset}</div>
      </div>

      <div className="row">
        <div className="col-3">Whoopdeedoo</div>
        <div className="col-9">{userDetails?.emotesTracking?.Whoopdeedoo}</div>
      </div>

      <div className="row">
        <div className="col-3">Wave</div>
        <div className="col-9">{userDetails?.emotesTracking?.Wave}</div>
      </div>

    </UserDetailLayout>
  )
}
