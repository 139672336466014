import React from 'react'
import {useForm} from "react-hook-form";
import {usePreferenceMutate} from '../../data/adminActivities';
import {showToast} from '../../util/helpers';
import Spinner from '../common/spinner';

export default function Preferences({admin,show,setShow,refetch}) {
    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: preferenceMutate, isLoading: loading } = usePreferenceMutate();
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
            preferenceMutate(
                {
                    ...inputs,
                },
                {
                    onSuccess: (result) => {
                        if(result.data.message === 'success'){
                            showToast('Preferences updated Successfully ','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                            refetch()
                        }else{
                            showToast(result.data.message,'error')
                        }
                    }
                })
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={loading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>Set Preferences</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="col-form-label font-weight-bold">Page size</label>
                                    <input className="form-control" type="number" defaultValue={admin?.preferences?.pageSize} placeholder="Enter page size" {...register('pageSize',{required:true})} min='1'/>
                                </div>
                            </div>
                            </div>
                        </div>
                        <input type="hidden" defaultValue={admin?._id} {...register('id',{required:true})} />

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2" type='submit'>
                                    {'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
