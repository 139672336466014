import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getAdminActivity({queryKey}){
    const {limit,pageIndex,search} = queryKey[1]
    const {pageSize} = getPagination()
    const { data } =await http.get(apiConfigration.activityTracking.all+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)
    return data;
}

export function useFetchAdminActivityQuery(options){
    return useQuery(
        ['AdminActivity', options],
        getAdminActivity
      )
}

async function updatePreference(input){
    const {id}=input;
    return http.post(`${apiConfigration.admin.post}/${id}`,{preferences:input} )
}

export function usePreferenceMutate(){
    return useMutation('updatePreference',updatePreference)
}