import http from "./http";

import {useMutation} from "react-query";

function login(input){
    return http.post('http://167.99.91.36:2020/api/login',input)
}
export function useLoginMutation(){
    return useMutation(login)
}
