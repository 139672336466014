import React, {useEffect} from 'react'
import {useForm} from "react-hook-form";
import {
    avatarSize,
    dimensionGender,
    language,
    playerMembershipLevel,
    type,
    useAddEditDimensionMutation
} from '../../../data/region';
import {showToast} from '../../../util/helpers';
import Spinner from '../../common/spinner';

export default function AddEditModel({dimension,roomsConfigId,show,setShow,setLoading,refetch}) {

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addEditDimensionMutate, isLoading: fetchloading } = useAddEditDimensionMutation();

    useEffect(()=>{
        if(dimension?.Language){
            reset(getDefaultValues())
        }
    },[dimension?.Language])

    function getDefaultValues(){
        return {
            Language:dimension?.Language,
            PerformerGender:dimension?.PerformerGender,
            AvatarGender:dimension?.AvatarGender,
            AvatarSize:dimension?.AvatarSize,
            Type:dimension?.Type,
            PlayerMembershipLevel:dimension?.PlayerMembershipLevel,
            population:dimension?.population,
            id:dimension?._id,
        }  
    }
    
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        setLoading(true)
        
            addEditDimensionMutate(
                {
                    ...inputs,roomsConfig_id:roomsConfigId
                },
                {
                    onSuccess: (result) => {
                        setLoading(false)
                        if(result.data.message=='success'){
                            showToast('Dimension Successfully stored','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                        refetch()
                    }
                })
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>{!dimension ? 'Add' : 'Edit'} Dimension</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Language</label>
                                    <select className="form-control" {...register('Language',{required:true})}>
                                        {
                                            language.map((item,i)=>{
                                                return(

                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Performer Gender</label>
                                    <select className="form-control" {...register('PerformerGender',{required:true})}>
                                        {
                                            dimensionGender.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Avatar Gender</label>
                                    <select className="form-control" {...register('AvatarGender',{required:true})}>
                                        {
                                            dimensionGender.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Avatar Size</label>
                                    <select className="form-control" {...register('AvatarSize',{required:true})}>
                                        {
                                            avatarSize.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Type</label>
                                    <select className="form-control" {...register('Type',{required:true})}>
                                        {
                                            type.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Membership Level</label>
                                    <select className="form-control" {...register('PlayerMembershipLevel',{required:true})}>
                                        {
                                            playerMembershipLevel.map((item,i)=>{
                                                return(
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Population</label>
                                        <input className="form-control" type="number" placeholder="Enter population" {...register('population',{required:true})}/>
                                    </div>
                                </div>
                                </div>
                            </div>
                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2">
                                    {!dimension ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
