import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getReport({queryKey}){
    const {limit,pageIndex,search} = queryKey[1]
    const {pageSize} = getPagination()
    const { data } =await http.get(apiConfigration.report.abuseReport+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)
    return data;
}


export function useFetchReportQuery(options){
    return useQuery(
        ['Report', options],
        getReport
      )
}

