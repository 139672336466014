import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import { showToast,checkPermission } from '../../util/helpers';
import { permissionList } from '../../data/permission-list';
import { useBadgesQuery, useUpdateSortOrderMutation, useDeleteBadgesMuatation } from '../../data/badges'
import { Link, useNavigate } from "react-router-dom";
import Spinner from '../../components/common/spinner';
import DeleteConfirm from '../../components/common/deleteConfirm';
import { apiConfigration } from '../../config/api';

export default function Badges() {
  const { data: dataList, isLoading: fetchloading ,refetch}  = useBadgesQuery()
  const navigate = useNavigate();
  const [loading,setLoading]=useState('')
  const {mutate:updateSortorderMutate, isLoading: updateloading  } =useUpdateSortOrderMutation()
  const {mutate:deleteMutate , isLoading: deleteloading } =useDeleteBadgesMuatation()

  function updateSortorder(index, isUp = true){
    if (isUp) {
      dataList[index - 1].sortOrder = dataList[index - 1].sortOrder + 1;
      dataList[index].sortOrder = dataList[index].sortOrder - 1;
    } else {
      dataList[index + 1].sortOrder = dataList[index + 1].sortOrder - 1;
      dataList[index].sortOrder = dataList[index].sortOrder + 1;
    }
    const newOrder= {}

    dataList?.forEach(element => {
      newOrder[element._id] = element.sortOrder;
    });

    updateSortorderMutate(
      { sortorder: newOrder },
      {
        onSuccess: (result) => {
          if(result.status){
            showToast('Badge Successfully updated!','success')
            refetch()
          }
        },
        onError: (error) => {
          console.log(error.message);
        }
      })
  }
  function viewMembers(item){
    navigate(`/badges/members?badge=${item.name}&id=${item._id}`);
  }
  const addEditBadges=(item)=>{
    if(item){
      navigate(`/badges/create-edit?id=${item._id}&name=${item.name}&group=${item.group}&isCheck=${item.roles}&file=${item.logo}&color=${item.color?item.color.substring(1):''}`);
    }
    
  }
  
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading||updateloading||deleteloading||loading}/>
        <div className="header-title">
          <div className="row">
            <div className="col-2"> Badge Rank </div>
            <div className="col-2"> Badge Name </div>
            <div className="col-2"> Associated Roles </div>
            <div className="col-1"> Badge Color </div>
            <div className="col-2"> Badge Group </div>
            <div className="col-1"> Members </div>
            <div className="col-2"> Action </div>
          </div>
        </div>
        {
          dataList?
          (dataList?.map((item,i)=>{
            return (
              <div className="row my-2" key={i}>
                <div className="col-2 text-center">
                  <div className="d-flex justify-content-center">
                    <div className="mx-1 w-10">
                      {
                        i!=0&&(
                          <i className="fa fa-arrow-up cursor fa-20 color-green" onClick={()=>updateSortorder(i)} ></i>
                        )
                      }
                    </div>
                    <div className="mx-1 w-10">
                      {
                        i+1!=dataList?.length&&(
                          <i className="fa fa-arrow-down cursor fa-20 color-red" onClick={()=>updateSortorder(i,false)}></i>
                        )
                      }
                    </div>
                    <div className="mx-1 w-10">
                      {item?.sortOrder}
                    </div>
                  </div>
                </div>
                <div className="col-2 text-center"> {item?.name} </div>
                <div className="col-2 text-center"> {item?.roles.map((role,i)=>{
                  return (i!==item?.roles.length-1)&&(item?.roles.length>1)?role+",":role
                })} </div>
                <div className="col-1 centers" >
                  <div className='badgebox' style={{backgroundColor:item?.color}}></div>
                </div>
                <div className="col-2 text-center"> {item?.group} </div>
                <div className="col-1 text-center"> {item?.users_count || 0} </div>
                <div className="col-2 text-center" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  {
                    (item?.users_count > 0)&&(
                      <i className="fa fa-eye cursor fa-20 mx-1" tooltip="View Members"
                        onClick={()=>viewMembers(item)}></i>
                    )
                  }
                  {
                    checkPermission(permissionList.UpdateBadges)&&(
                      <i  className="fa fa-pencil cursor fa-20 mx-1" tooltip="Edit"
                        onClick={()=>addEditBadges(item)}></i>
                    )
                  }
                  {
                    checkPermission(permissionList.DeleteBadges)&&(
                        <DeleteConfirm name="Badge" big="true" url={`${apiConfigration.badges.remove}/${item?._id}`} refetch={()=>refetch()} setLoading={(value)=>setLoading(value)}/>
                    )
                  }
                </div>
              </div> 
            )
          })):''
        }
        {/* [permission]="permissionList.AddBadges"*/}

        <div className="row my-2">
          <div className="col-12">
            <Link to="/badges/create-edit" type="button" className="btn btn-danger" onClick={addEditBadges()}>
              Add New Badge
            </Link>
          </div>
        </div>

    </DefaultLayout>
  )
}
