import React from 'react'
import Header from '../components/layout/header/Header'
import Sidebar from '../components/layout/sidebar/Sidebar'
import { ToastContainer} from 'react-toastify';

const DefaultLayout = ({ children }) => {
  return (
    <>
    <Header />
    {/* animated fadeIn */}
    <div className="app-body">
      <Sidebar />
      <main className="main">
        <div className="container-fluid mt-3">
        
          <div className="">
          {children}
          </div>
        </div>
      </main>
      <ToastContainer/>
    </div>
    </>
  )
}

export default DefaultLayout
