import React, {useState} from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchBadgesMemberQuery} from '../../data/badges'
import {useSearchParams} from 'react-router-dom'
import Pagination from '../../components/common/pagination'
import {environment} from '../../config/environment'
import Spinner from '../../components/common/spinner';
import {useNavigate} from "react-router";

export default function Members() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [pageSize,setPageSize]= useState(25)
    const [skip,setSkip]= useState(0)
    const navigate = useNavigate()
    const [searchParams,setSearchParams]=useSearchParams()
    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchBadgesMemberQuery({search,pageIndex,limit:pageSize,skip,id:searchParams.get('id')})

    function setDefaultImage(event){
        event.target.src="/images/no-profile-image.jpg"
    }

    function getBadgesUser(){
    }

    function resetData(){
        document.querySelector('#search').value=''
        setSearch('')
        refetch()
    }
    function ViewMemberDetails(item){
        navigate(`/members/detail/overview?id=${item._id}&page=1&pagesize=25`)
    }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading}/>

        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>getBadgesUser()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" onChange={(event)=>setSearch(event.target.value)} />
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                <thead>
                    <tr>
                    <th className="text-center">Profile</th>
                    <th>Member ID</th>
                    <th>Member Name</th>
                    <th>Email</th>
                    <th>Dob</th>
                    <th>Membership</th>
                    <th>Gender</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        dataList?.data?.map((item,i)=>{
                            return (
                                <tr key={i}>
                                    <td className="text-center">
                                        <a href={environment.webLobbyUrl+'profile?memberId='+item._id} target="blank">
                                        <img className="profile cursor" src={environment.SOCKET_ENDPOINT+item.pp} alt=""
                                            onError={setDefaultImage}/>
                                        </a>
                                    </td>
                                    <td>
                                        <a className="custom-a" href="" onClick={() => ViewMemberDetails(item)}>
                                        {item?._id}
                                        </a>
                                    </td>
                                    <td>
                                        <a className="custom-a" href="" onClick={() => ViewMemberDetails(item)}>
                                        {item?.un}
                                        </a>
                                    </td>
                                    <td>{item?.email}</td>
                                    <td>{item?.dob}</td>
                                    <td>{item?.membership}</td>
                                    <td>{item?.gender}</td>
                                    <td>
                                        {
                                            item.isOnline===1&&(
                                                <span className="badge bg-success">Online</span>
                                            )
                                        }
                                        {
                                            item.isOnline===0&&(
                                                <span className="badge bg-danger">Offline</span>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={pageSize} setPageIndex={setPageIndex} setPageSize={setPageSize}/>
            </div>
        </div>
    </DefaultLayout>
  )
}
