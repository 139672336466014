import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useEditAdvertiseMutation,useAddAdvertiseMutation,typeList,showAtList } from '../../data/announcements';
import { getBase64Encoded,showToast } from '../../util/helpers';
import moment from 'moment';
import { environment } from '../../config/environment';

export default function WorldBoardCastModel({show,setShow,isAddNew,announcement,setLoading,refetch}) {
    const [mediaType,setMediaType] = useState('image')
    const [viewFile,setViewFile]= useState()
    const [file,setFile]= useState('')

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addAdvertiseMutate, isLoading: loading } = useAddAdvertiseMutation();
    const { mutate: editAdvertiseMutate, isLoading: loading2 } = useEditAdvertiseMutation();
    
    useEffect(()=>{
        if(announcement?.res){
            setViewFile(environment.baseurl+'/'+announcement?.res)
        }
    },[announcement?.res])
    useEffect(()=>{
        if(announcement?.type){
            reset(getDefaultValues())
            setMediaType(announcement?.type)
        }
    },[announcement?.type])
    
    function getDefaultValues(){
        return {
            id:announcement?._id,
            title:announcement?.title,
            showAt:announcement?.showAt,
            link:announcement?.link,
            end_at:moment(announcement?.end_at).format('YYYY-MM-DD'),
            start_from:moment(announcement?.start_from).format('YYYY-MM-DD'),
            type:announcement?.type
        }  
    }
    function closeModel(){
        setShow(false)
    }

    function onSubmit(inputs)
    {
        setLoading(true)
        if(inputs._id){
            editAdvertiseMutate(
                {
                    ...inputs,type:mediaType,file:file,_id:inputs._id
                },
                {
                    onSuccess: (result) => {
                        setLoading(false)
                        if(result.data.message === 'success'){
                            showToast('Announcement Successfully updated','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                        refetch()
                    }
                })
        }else{
            addAdvertiseMutate(
                {
                    ...inputs,type:mediaType,file:file
                },
                {
                    onSuccess: (result) => {
                        setLoading(false)
                        if(result.data.message === 'success'){
                            showToast('Announcement Successfully added','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                        }else{
                            showToast(result.data.message,'error')
                        }
                        refetch()
                    }
                })
        }
        
    }
    function loadBanner(){
        document.querySelector('#banner').click();
    }

    function changeBanner(event){
        if (event.target.files && event.target.files.length > 0) {
          getBase64Encoded(event.target.files[0]).then((data) => {
            setViewFile(data.fileType + data.base64);
          });
          setFile(event.target.files[0])
        }
    }
    function setTypeValue(value){
        setMediaType(value)
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>{!announcement ? 'Add' : 'Edit'} Announcement</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>
                        <input type="hidden" {...register('_id')} value={announcement?._id} />
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="col-form-label font-weight-bold required">Title</label>
                                    <input className="form-control" type="text" placeholder="Enter title" {...register('title',{required:true})} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Type</label>
                                <div className="col-form-label d-flex flex-wrap">
                                    {
                                        typeList?.map((item,i)=>{
                                            return (
                                                <div className="form-check mr-2" key={i}>
                                                    <input className="form-check-input" type="radio" name="typeradios" value={item.value} checked={mediaType==item.value} onChange={(event)=>setTypeValue(event.target.value)}  />
                                                    <label className="form-check-label">{item.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">Start From</label>
                                <input className="form-control" type="date" {...register('start_from',{required:true})} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">End At</label>
                                <input className="form-control" type="date" {...register('end_at',{required:true})} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold">{mediaType === 'text' ? 'Text' : 'Link'}</label>
                                <input className="form-control" type="text" placeholder={'Enter '+ (mediaType === 'text' ? 'Text' : 'Link')}  {...register('link',{required:true})} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Show At</label>
                                <div className="col-form-label d-flex flex-wrap">
                                    {
                                        showAtList?.map((item,i)=>{
                                            return (<div className="form-check mr-2" key={i}>
                                                <input className="form-check-input" type="radio" name="showAtradios" value={item.value} {...register('showAt',{required:true})} />
                                                <label className="form-check-label">{item.name}</label>
                                            </div>)
                                        })
                                    }
                                </div>
                                </div>
                            </div>
                            {
                                (mediaType==='image'||mediaType==='video')&&(
                                    <div>
                                        <div className="col-md-12">
                                        <button className="btn btn-secondary" onClick={()=>loadBanner()}>
                                            {mediaType==='image' ? 'Image' : 'Video'}
                                                   Upload
                                        </button>
                                        <input id="banner" type="file" accept={mediaType+"/*"} style={{display: 'none'}}
                                            onChange={changeBanner} />
                                        </div>
                                        {
                                            viewFile&&(
                                                <div className="col-md-12 my-3">
                                                    <img src={viewFile} className="w-100" />
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2" type='submit'>
                                    {!isAddNew ? 'Save' : 'Update'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
