import http from "../util/http";
import { useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getBroadCastLog({queryKey}){
    const {limit,pageIndex,search,skip} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(apiConfigration.user.broadcastLog+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}

export function useFetchBroadCastLogQuery(options){
    return useQuery(
        ['getBroadCastLog', options],
        getBroadCastLog
      )
}