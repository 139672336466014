import React, {useState} from 'react'
import {useForm} from "react-hook-form";
import {useAddNoteMutation} from '../../../data/users';
import {getBase64Encoded, showToast} from '../../../util/helpers';
import Spinner from '../../../components/common/spinner';
import {useSearchParams} from 'react-router-dom';

export default function AddNote({show,setShow}) {

    const { register, handleSubmit, watch,reset, formState: { errors, } } = useForm();
    const { mutate: addNoteMutate, isLoading: fetchloading } = useAddNoteMutation();
    const [searchParams,setSearchParams]=useSearchParams()
    const [viewFile,setViewFile]= useState()
    const [file,setFile]=useState('')
    
    function closeModel(){
        setShow(false)
    }

    function changeBanner(event){
        if (event.target.files && event.target.files.length > 0) {
          getBase64Encoded(event.target.files[0]).then((data) => {
            setViewFile(data.fileType + data.base64);
          });
          setFile(event.target.files[0])
        }
    }

    function loadBanner(){
        document.querySelector('#banner').click();
    }

    function onSubmit(inputs)
    {
        
          addNoteMutate(
                {
                      note:inputs.note,file,_id:searchParams.get('id'),
                },
                {
                    onSuccess: (result) => {
                            showToast('User suspend Successful','success')
                            setTimeout(() => {
                                reset('')
                                setShow(false)
                            }, 800);
                    }
                })
        
    }

  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <Spinner loading={fetchloading}/>
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>Add Member Note</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>                       
                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <label className="col-form-label font-weight-bold required">Note</label>
                                <textarea className="form-control" rows="3" {...register('note',{required:true})}></textarea>
                                </div>
                            </div>

                            <div className="col-12 mt-2">
                            <input id="banner" type="file" accept='image/*' {...register('file')} style={{display: 'none'}} onChange={changeBanner} />
                                <button type='button' className="btn btn-secondary" onClick={()=>loadBanner()}> Image Upload </button>
                            </div>
                            <div className="col-12 my-3">
                                {
                                    viewFile&&(
                                        <img src={viewFile} style={{width:'50px'}}/>
                                    )
                                }
                            </div>
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2">
                                    save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}
