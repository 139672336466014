import React, {useState} from 'react'
import UserDetailLayout from '../../../layouts/UserDetailLayout'
import {useUpdateIDMutation, useUserOverviewQuery} from '../../../data/user-overview'
import moment from 'moment'
import {useForm} from "react-hook-form";
import {useSearchParams} from 'react-router-dom'
import {showToast} from '../../../util/helpers';
import {environment} from '../../../config/environment'
import Spinner from '../../../components/common/spinner'

export default function UserId() {
  const [age,setAge]= useState()
  const [searchParams,setSearchParams]=useSearchParams()
  var defaultRotate = 0;

  const { data:userDetails, isLoading: fetchloading ,refetch}  = useUserOverviewQuery({id:searchParams.get('id')})
  const { register, handleSubmit, watch, formState: { errors } } = useForm({defaultValues:{
                                                                      firstName:userDetails?.userIdentities?.firstName,
                                                                      lastName:userDetails?.userIdentities?.lastName,
                                                                      DOB:userDetails?.userIdentities?.DOB,
                                                                    }});
  const { mutate: updateMutate, isLoading: updateloading } = useUpdateIDMutation();

  function imageRotate(index){
    defaultRotate = defaultRotate + 45;
    document.getElementById('memberProfile' + index).style.transform = 'rotate(' + defaultRotate + 'deg)';
  }                
  function removeIdentityPic(pos){
    var data = {
      _id: userDetails?.userIdentities.userIdentities._id,
      pos: (pos + 1)
    };
  }

  function getAge(value) {
    setAge(moment().diff(value, 'years'));
  }
  function loadBanner(){
    document.querySelector('#upload-id').click();
  }
  function clickSubmit(){
    document.querySelector('#submit-id').click();
  }
  function onSubmit(data){
    updateMutate({...data,DOB:moment(data.DOB).format('YYYY-MM-DD'),uid:searchParams.get('id')},
      {
          onSuccess: (result) => {
            if(result.data.message==='success'){
                showToast('User Identity updated successfully!','success')
            }else{
              showToast(result.data.message,'error')
            }
          },
          onError: (error) => {
            console.log(error.message);
          }
        }
  )
  }
  return (
    <UserDetailLayout>
      <Spinner loading={fetchloading||updateloading}/>

      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12 d-flex">
          {
            userDetails?.userIdentities?.pics?.map((item,i)=>{
              return (
                <div className="member-profile">
                  <img  src={environment.baseurl+'/'+item} alt="" style={{height:'100px'}} />
                  <div className="rotate-profile" tooltip="Rotate" onClick={()=>imageRotate((i+1))}>
                    <i className="fa fa-repeat"></i>
                  </div>
                  <div className="remove-profile" tooltip="Remove" onClick={()=>removeIdentityPic(i)}>
                    <i className="fa fa-times"></i>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="col-form-label font-weight-bold required">First Name</label>
            <input className="form-control" type="text" placeholder="Enter first name" {...register('firstName',{required:true})} defaultValue={userDetails?.userIdentities?.firstName} />
          </div>
          <div className="form-group">
            <label className="col-form-label font-weight-bold required">Last Name</label>
            <input className="form-control" type="text" placeholder="Enter last name" {...register('lastName',{required:true})} defaultValue={userDetails?.userIdentities?.lastName} />
          </div>
          <div className="form-group">
            <label className="col-form-label font-weight-bold required">DOB</label>
            <input className="form-control" id="DOB" type="date" {...register('DOB',{required:true})} onChange={(e)=>getAge(e.target.value)} defaultValue={moment(userDetails?.userIdentities?.DOB).format('YYYY-MM-DD')} />
          </div>

          <div className="mt-2 mb-1">
            <span> Age Check : </span>
            <span className="float-right">
              {
                (age>=18)&&(
                  <span >
                    <i className="fa fa-check color-green"></i>
                  </span>
                )
              }
              {
                (age<18)&&(
                  <span >
                    <i className="fa fa-times color-red"></i>
                  </span>
                )
              }
            </span>
          </div>
          <div>
            <input className="d-none" id="upload-id" type="file" accept='image/*' {...register('file')} onChange={()=>clickSubmit()} />
            <button type="submit" className="btn btn-danger mt-2 mr-2"  onClick={()=>loadBanner()}>
              <i className="fa fa-user-times"></i> Upload ID
            </button>

            <button className="btn btn-danger mt-2" id="submit-id">
              <i className="fa fa-user-times"></i> Submit
            </button>
          </div>
        </div>
      </form>

    </UserDetailLayout>
  )
}
