import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getEmailTemplate({queryKey}){
    const {limit,pageIndex,search} = queryKey[1]
    const {pageSize} = getPagination()
    const { data } =await http.get(apiConfigration.emailTemp.api+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)
    return data;
}


export function useFetchEmailTemplateQuery(options){
    return useQuery(
        ['EmailTemplate', options],
        getEmailTemplate
      )
}

export const emailType = [
    { name: 'Private Email', value: 'private_email' },
    { name: 'Email', value: 'email' },
    { name: 'Both', value: 'both' },
  ];

async function editEmailTemplate(input){
    return http.post(`${apiConfigration.emailTemp.api}`,input)
}
export function useAddEmailTemplateMutation(){
    return useMutation('editEmailTemplate',editEmailTemplate)
}

async function getUserSearch({queryKey}){
    const {search} = queryKey[1]
    if(search){
        const { data } =await http.get(`${apiConfigration.user.all}?search=${search}`)
        return data;
    }else{
        return [];
    }

}

export function useUserSearchQuery(options){
    return useQuery(
        ['getUserSearch', options],
        getUserSearch
      )
}


