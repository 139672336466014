import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

async function getUserOverview({queryKey}){
    const {id} = queryKey[1]
    const { data } = await http.get(apiConfigration.user.api+'/'+id)
    return data.data;
}

export function useUserOverviewQuery(options){
    return useQuery(["getUserOverview",options],getUserOverview)
}

async function getAllBadges(){
    const { data } = await http.get(apiConfigration.badges.api)
    return data.data;
}

export function useAllBadgeQuery(){
    return useQuery("getAllBadges",getAllBadges)
}

async function getUserActivity({queryKey}){
    const {id,limit,pageIndex,search,skip} = queryKey[1]
    const {pageSize} = getPagination()
    const { data } = await http.get(`${apiConfigration.user.activity}/${id}?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)
    return data.data;
}

export function useUserActivityQuery(options){
    return useQuery(["getUserActivity",options],getUserActivity)
}

async function updateUserOverview(input){
    const {_id:id}=input;
    return http.post(`${apiConfigration.user.api}/${id}`,input)
}

export function useUpdateUserOverviewMutation(){
    return useMutation('updateUserOverview',updateUserOverview)
}

async function addUserBadge(data){
    return http.post(`${apiConfigration.user.addUserBadge}`, data)
}

export function useAddUserBadgeMutation(){
    return useMutation('addUserBadge',addUserBadge)
}

async function removeUserBadge(data){
    const {id,adminid}=data
    return http.post(`${apiConfigration.user.removeUserBadge}/${id}`, { adminid })
}

export function useRemoveBadgeMutation(){
    return useMutation('removeUserBadge',removeUserBadge)
}

async function updateId({firstName,lastName,DOB,file,uid}){
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('uid', uid);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('DOB', DOB);
    return http.post(`${apiConfigration.user.updateIdentity}`, formData)
}
export function useUpdateIDMutation(){
    return useMutation('updateId',updateId)
}
