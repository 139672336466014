import React, { useState } from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import { useDashboardQuery,graphList } from '../../data/dashboard'
import LineChart from '../../components/dashboard/LineChart'
import Spinner from '../../components/common/spinner';

export default function Dashboard() {
  const { data, isLoading: fetchloading }  = useDashboardQuery()
  const [selectedGraph,setSelectedGraph] = useState(1)

  return (
    <DefaultLayout>
      <Spinner loading={fetchloading}/>
        <div className="row my-3">
          <div className="col-lg-2 col-md-4">
            <div className="card bg-primary dashboard-count-card">
              <div>
                <img src="/images/user.svg" />
                <h5 className="mt-2">Total Members</h5>
                <h6 className="mb-0">{data?.totalUser}</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <div className="card bg-primary dashboard-count-card">
              <div>
                <img src="/images/users.svg" />
                <h5 className="mt-2">Total Online Members</h5>
                <h6 className="mb-0">{data?.onlineUser}</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="float-md-right">
                {
                  graphList.map((item,key)=>{
                    return (
                      <label key={key} className={`btn btn-outline-dark mr-2 d-md-inline-block d-block ${selectedGraph==item.type?'active':''}`} 
                            onClick={()=>setSelectedGraph(item.type)}>
                        {item.name}
                      </label>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div style={{height: "calc(100vh - 360px)"}}>
            <LineChart data={data} selectedGraph={selectedGraph}/>
          </div>
        </div>
    </DefaultLayout>
  )
}
