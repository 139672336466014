import React, {useState} from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'
import {useFetchRegionQuery} from '../../data/region.js'
import {useSearchParams} from 'react-router-dom'
import {getPagination} from "../../config/api";
import Pagination from '../../components/common/pagination'
import {checkPermission} from '../../util/helpers';
import {permissionList} from '../../data/permission-list';
import Spinner from '../../components/common/spinner';
import AddEditModel from '../../components/regions/addEditModel';
import Dimension from '../../components/regions/dimension';

export default function Region() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)
    const [modelShow2,setModelShow2]=useState(false)
    const [modelShow,setModelShow]=useState(false)
    const [loading,setLoading] =useState('')
    const [selectedRegion,setSelectedRegion] =useState('')
    const [roomsConfigId,setRoomsConfigId]= useState('')
    const { data:dataList, isLoading: fetchloading ,refetch}  = useFetchRegionQuery({search,pageIndex,limit,skip})

    function resetData(){
        document.querySelector('#search').value=''
        setSearch('')
        refetch()
    }

    function viewDimensionDetails(item){
        setRoomsConfigId(item._id)
        setModelShow2(true)
    }

    function addRegion(){
        setModelShow(true)
    }

    function editRegion(item){
        if(item){
            setSelectedRegion(item)
        }
        setModelShow(true)
    }
  return (
    <DefaultLayout>
        <Spinner loading={fetchloading||loading}/>

        <div className="row">
            <div className="col-md-3">
                <div className="input-group">
                    <div className="input-group-prepend" onClick={()=>refetch()}>
                        <span className="input-group-text"><i className="fa fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search" id="search" onChange={(event)=>setSearch(event.target.value)} />
                    <button type="button" className="btn btn-primary ml-2" onClick={()=>resetData()}>Clear</button>
                </div>
            </div>
            <div className="col-md-9 text-md-right mt-2 mt-md-0">
                {
                    checkPermission(permissionList.ViewRegions)&&(
                        <button type="button" className="btn btn-primary" onClick={()=>addRegion()}>
                            <i className="fa fa-plus"></i> Add
                        </button>
                    )   
                }
            </div>
        </div>
        <div className="row center-content">
            <div className="col-12">
                <table className="table table-striped table-responsive-md my-3">
                    <thead>
                        <tr>
                            <th>Room</th>
                            <th>Cover Charge</th>
                            <th>Players</th>
                            <th style={{width: '5%'}} className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            dataList?.data?.map((item,i)=>{
                                return (
                                    <tr key={i}>
                                        <td>{item?.room}</td>
                                        <td>{item?.cover_charge}</td>
                                        <td>{item?.users_count || 0}</td>
                                       
                                        <td className='text-center'>
                                            {
                                                checkPermission(permissionList.ViewDimension)&&(
                                                    <i className="fa fa-eye cursor" onClick={()=>viewDimensionDetails(item)} placement="left" tooltip="View Dimension"></i>
                                                )
                                            }
                                            {
                                                checkPermission(permissionList.UpdateRegions)&&(
                                                    <i className="fa fa-pencil cursor" onClick={()=>editRegion(item)} placement="left" tooltip="Edit"></i>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
            <div className="col-12">
                <Pagination total={dataList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
            </div>
            {
                modelShow&&(
                    <AddEditModel region={selectedRegion} show={modelShow} setShow={()=>setModelShow()} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()}/>
                )
            }
            {
                modelShow2&&roomsConfigId&&(
                    <Dimension roomsConfigId={roomsConfigId} show={modelShow2} setShow={()=>setModelShow2()} setLoading={(value)=>setLoading(value)} refetch={()=>refetch()}/>
                )
            }
        </div>
    </DefaultLayout>
  )
}
