import React,{useState} from 'react'
import UserDetailLayout from '../../../layouts/UserDetailLayout'
import {useFetchAdminActivityLogQuery} from '../../../data/admin-activity-log.js'
import moment from 'moment'
import Spinner from '../../../components/common/spinner'
import { getPagination} from "../../../config/api";
import Pagination from '../../../components/common/pagination'
import { useSearchParams } from 'react-router-dom'

export default function AdminLog() {
    const [search,setSearch]= useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [limit,setLimit]= useState(25)
    const [skip,setSkip]= useState(0)

    const [searchParams,setSearchParams]=useSearchParams()
    const { data:activityList, isLoading: fetchloading ,refetch:refetch}  = useFetchAdminActivityLogQuery({search,pageIndex,limit,skip,id:searchParams.get('id')})

  return (
    <UserDetailLayout>
      <Spinner loading={fetchloading}/>
      <div className="row">
        <div className="col-12">
          <table className="table table-striped table-responsive-md my-3">
            <thead>
              <tr>
                <th>Date / Time</th>
                <th>Admin Name</th>
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
            {
              activityList?.data.map((item,i)=>{
                return(
                  <tr key={i}>
                    <td>{moment(item?.createdAt).format('YYYY-MM-DD HH:mm:ss:SSS') }</td>
                    <td>{item?.name}</td>
                    <td>{item?.activity}</td>
                  </tr>
                )
              })
            }
              
            </tbody>
          </table>
        </div>
        <div className="col-12">
        <Pagination total={activityList?.count} pageIndex={pageIndex} pageSize={getPagination().pageSize} setPageIndex={setPageIndex} setPageSize={setLimit}/>
        </div>
      </div>
    {
      activityList?.data.length==0&&(
        <div>
          <tr>
            <td colspan="100" className="text-center font-2xl">No data available</td>
          </tr>
        </div>
      )
    }


    </UserDetailLayout>
  )
}
