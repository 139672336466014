import React, {useState} from 'react'
import {totalPermissionList} from '../../data/adminList';

export default function AccessListModel({show,setShow,sendPermissionList,oldList}) {
    const [selectedPermission,setSelectedPermission]= useState(oldList)
    const [totalPermission,setTotalPermissionList]=useState(totalPermissionList)

    function closeModel(){
        setShow(false)
    }

    function isCheckChange(item) {
        var temp=selectedPermission;
        if (!temp.includes(item.code)) {
          temp.push(item.code);
        } else {
          temp = temp.filter(code => code != item.code);
        }
        setSelectedPermission(temp)
        sendPermissionList(temp)
    }
    function isSelected(item){
        return selectedPermission?.includes(item.code)
    }
  return (
    <>
        {
            show&&(
                <div className={`modal-backdrop fade in ${show?'show':''}`}></div>
            )
        }
        <div role="dialog" tabIndex="-1" className={`modal fade ${show?'show':''}`} style={{display: (show?'block':'none')}} aria-modal="true">
            <div role="document" className="modal-dialog">
                <div className="modal-content">
                    <div className="card m-0">
                        <div className="card-header modelHeader">
                            <div className="row">
                            <div className="col-10 align-self-center">
                                <h5>Member List</h5>
                            </div>
                            <div className="col-2 text-right">
                                <i className="fa fa-times cursor" onClick={()=>closeModel()}></i>
                            </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                            <div className="col-12">
                                <div className="form-group">

                                <table className="table table-striped table-responsive-md my-3">
                                <thead>
                                    <tr>
                                        <th style={{width: '10%'}} className="text-center"></th>
                                        <th>Access Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        totalPermission?.map((item,i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center align-middle">
                                                        <input className="cursor" type="checkbox" checked={isSelected(item)}  onChange={()=>isCheckChange(item)} />
                                                    </td>
                                                    <td>{item?.name}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>

                        <div className="card-footer modelFooter">
                            <div className="float-right">
                                <button className="btn btn-primary mr-2" onClick={()=>closeModel()}>Save</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
