import React  from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {getUser} from "../../util/helpers";
import {DOTS, usePagination} from "./usePagination";

export default function Pagination({total,pageSize,pageIndex,setPageIndex,setPageSize}) {
    const navigate = useNavigate();
    const getPathName = window.location.pathname
    const data = new URL(window.location.href);
    const [searchParams2,setSearchParams2] = useSearchParams()
    const checkId = searchParams2.get('id')
    const searchParams = data.searchParams;
    const id = searchParams.get('id')
    const page = searchParams.get('page') || 0
    const currentPageNumber = parseInt(page)
    const siblingCount = 1;

    const paginationRange = usePagination({
        total,
        pageSize,
        siblingCount,
        currentPageNumber,
    });

    if (paginationRange?.length === 0 && paginationRange?.length < 2) {
        return null;
    }

    function handleChangePageSize(event){
        const datas = {
            id: getUser()?._id,
            pageSize: event
        }
        const data=getUser()
        data.preferences=datas
        const {id}=datas;
        window.localStorage.setItem('user',JSON.stringify(data))
        setPageSize(event)
        navigate(checkId !== null ?`${getPathName}?id=${checkId}&page=${page}&pagesize=${event}`: `${getPathName}?page=${page}&pagesize=${event}`)
    }

  return (
<div  className="custom-pag d-md-flex ng-star-inserted">
  <div>
    <span >
      <select className="custom-select w-auto mb-2 mb-md-0 ng-valid ng-dirty ng-touched" defaultValue={pageSize} onChange={(event)=> handleChangePageSize(event.target.value)}>
        <option value="10" className="ng-star-inserted" >10</option>
        <option value="25" className="ng-star-inserted" >25</option>
        <option value="50" className="ng-star-inserted" >50</option>
        <option value="100" className="ng-star-inserted">100</option>
      </select>
    </span>
    <span  className="ml-1 fw-500">
      PageSize : {pageSize} (Total Records : {total})
    </span>
  </div>
  <div  className="ng-untouched ng-valid ng-dirty">
      <ul className="pagination">
          <li className="pagination-first page-item ng-star-inserted">
              <span className="page-link" onClick={()=> {
                  return(
                      <>
                          {pageIndex !== 0 ?
                              setPageIndex(0) : ''
                          }
                          {
                 navigate(id === null ? `${getPathName}?page=${1}&pagesize=${pageSize}` : `${getPathName}?id=${id}&page=${1}&pagesize=${pageSize}`)
                          }
                      </>
              )}}>«</span>
          </li>
          <li className="pagination-prev page-item ng-star-inserted">
              <span className="page-link"
                    onClick={()=> {
                        const currentPage = page > 1 ? page -1   : 1
                        return(
                            <>
                                {
                                    pageIndex!==0?setPageIndex(pageIndex-1):''
                                }
                                {
                                    navigate(id === null ? `${getPathName}?page=${currentPage}&pagesize=${pageSize}` : `${getPathName}?id=${id}&page=${currentPage}&pagesize=${pageSize}`)
                                }
                            </>
                        )}}
              >‹</span>
          </li>
          {paginationRange?.length !== 0 && paginationRange?.map((pageNumber, index) => {
                  const url = id === null ? `${getPathName}?page=${pageNumber}&pagesize=${pageSize}` : `${getPathName}?id=${id}&page=${pageNumber}&pagesize=${pageSize}`
              if (pageNumber === DOTS) {
                  return <li key={index} className="pagination-item dots"><span className="page-link">&#8230;</span></li>;
              }

              return (
                  <li key={index} className={`pagination-page page-item ${pageIndex === pageNumber - 1 ?'active':''} ng-star-inserted`}>
                               <span onClick={()=> {
                                  setPageIndex(pageNumber -1)
                                  navigate(url)
                              }} className={`page-link`}>{pageNumber}</span>
                        </li>
              );
          })}

          <li className="pagination-next page-item ng-star-inserted">
              <span  className="page-link"
                     onClick={()=> {
                         const currentPage = Math.ceil(total/pageSize) > parseInt(page) ? parseInt(page) + 1  : Math.ceil(total/pageSize)
                         return(
                             <>
                                 {
                                     pageIndex + 1!== Math.ceil(total/pageSize)?setPageIndex(pageIndex + 1):''
                                 }
                                 {
                                     navigate(id === null ? `${getPathName}?page=${currentPage}&pagesize=${pageSize}` : `${getPathName}?id=${id}&page=${currentPage}&pagesize=${pageSize}`)
                                 }
                             </>
                         )}}

              >›</span>
          </li>
          <li className="pagination-last page-item ng-star-inserted">
              <span className="page-link"
                    onClick={()=> {
                        return(
                            <>
                                {
                                    pageIndex!==Math.ceil(total/pageSize)?setPageIndex(Math.ceil(total/pageSize)-1):''
                                }
                                {
                                    navigate(id === null ? `${getPathName}?page=${Math.ceil(total/pageSize)}&pagesize=${pageSize}` : `${getPathName}?id=${id}&page=${Math.ceil(total/pageSize)}&pagesize=${pageSize}`)
                                }
                            </>
                        )}}

                    >»</span>
          </li>
      </ul>
</div>
</div>
  )
}
