import React from 'react'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Sidebar() {
  const navigate =useNavigate()
  function isActive(name){
    return (typeof window !== "undefined") ? window.location.href.includes(name):''
  }

  function searchMember(event){
    if(event.key === "Enter"){
      if(event.target.value){
        navigate('/members?search='+event.target.value)
      }
    }
  }

  return (
    <div className="sidebar">
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text border-rd-0"><i className="fa fa-search"></i></span>
      </div>
      <input type="text" placeholder="Member Search" onKeyDown={(event)=>searchMember(event)} className="form-control border-rd-0" />
    </div>

    <div className="ps sidebar-nav ps--active-y" role="nav">
      <div className="nav">
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('dashboard')}`} to="/dashboard">
            <div>
              <i className="nav-icon fa fa-tachometer ng-star-inserted"></i>
              Dashboard
            </div>
          </NavLink>
        </div>
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('members')}`} to="/members?page=1&pagesize=25">
            <div>
              <i className="nav-icon fa fa-users ng-star-inserted"></i>
              Members
            </div>
          </NavLink>
        </div>
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('badges')}`} to="/badges">
            <div>
              <i className="nav-icon fa fa-empire ng-star-inserted"></i>
              Badge Editor
            </div>
          </NavLink >
        </div>
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('announcement')}`} to="/announcement?page=1&pagesize=25">
            <div>
              <i className="nav-icon fa fa-newspaper-o ng-star-inserted"></i>
              Announcement
            </div>
          </NavLink >
        </div>
        
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('email-template')}`} to="/email-template?page=1&pagesize=25">
            <div>
              <i className="nav-icon fa fa-envelope ng-star-inserted"></i>
              Private Mail & Email
            </div>
          </NavLink >
        </div>
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('admin-activity')}`} to="/admin-activity?page=1&pagesize=25">
            <div>
              <i className="nav-icon fa fa-user ng-star-inserted"></i>
              Admin Activity
            </div>
          </NavLink >
        </div>
        <div className="nav-item ng-star-inserted">
          <NavLink className={`nav-link ng-star-inserted ${isActive('broadcast-log')}`} to="/broadcast-log?page=1&pagesize=25">
            <div>
              <i className="nav-icon fa fa-globe ng-star-inserted"></i>
              Broadcast Log
            </div>
          </NavLink >
        </div>
      </div>
    </div>
  </div>
  )
}
