import React,{useState} from 'react'
import Dropdown from './Dropdown'
import ResetPassword from '../resetPassword'
import Preferences from '../preference'
export default function Header() {
  const [modelShow,setModelShow]=useState(false)
  const [modelShow2,setModelShow2]=useState(false)
  function toggleSideBar(){
    let body=document.querySelector('body')
    if(body.classList.contains('sidebar-show')){
      body.classList.remove('sidebar-show')
    }else{
      body.classList.add('sidebar-show')
    }
  }
  return (
    <div className="app-header navbar">
      <button type="button" appsidebartoggler="" onClick={()=>toggleSideBar()} className="d-lg-none navbar-toggler ng-star-inserted">
        <span className="navbar-toggler-icon"></span>
      </button>
      <a className="navbar-brand" href="/dashboard">
        <img src="/images/logo.png" alt='' width="100" height="50" className="navbar-brand-full ng-star-inserted" />
      </a>
      <ul className="nav navbar-nav ml-auto">
        <Dropdown setModelShow={(value)=>setModelShow(value)} setModelShow2={(value)=>setModelShow2(value)}/>
      </ul>
      {
          modelShow&&(
              <ResetPassword show={modelShow} setShow={()=>setModelShow()} />
          )
      }
      {
          modelShow2&&(
              <Preferences show={modelShow2} setShow={()=>setModelShow2()}/>
          )
      }
    </div>
  )
}
