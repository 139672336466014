import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";
import moment from "moment";

export const typeList = [
    { name: 'Image', value: 'image' },
    { name: 'Video', value: 'video' },
    { name: 'Text', value: 'text' },
  ];

async function getAnnouncement({queryKey}){
    const {limit,pageIndex,search,showAt} = queryKey[1]
    const {pageSize} = getPagination()
    const { data } =await http.get(apiConfigration.advertisement.api+`?search=${search}&pageIndex=${(pageIndex)}&showAt=${showAt}&limit=${limit}&skip=${(pageIndex) * pageSize}`)
    return data;
}
export function useAnnouncementQuery(options){
    return useQuery(
        ['getAnnouncement', options],
        getAnnouncement
      )
}

export const showAtList = [
    { name: 'Top Scroll Feed', value: 'top_scroll_feed' },
    { name: 'Lobby News', value: 'lobby_news' },
    { name: 'Loading Tips', value: 'loading_tips' },
    { name: 'Login Message', value: 'login_message' },
  ];

export function showAtName(value) {
    const data = showAtList.find(m => m.value == value);
    if (data && data.name) {
      return data.name;
    }
    return '';
}

async function createWorldAnnouncements(data){
    
    return http.post(`${apiConfigration.advertisement.toAll}`,data)
}
export function useWorldAnnouncementsMutation(){
    return useMutation('createWorldAnnouncements',createWorldAnnouncements)
}

function createFormData({title,type,showAt,link,file,start_from,end_at}){
    const formData = new FormData();
    formData.append('title', title);
    formData.append('type', type);
    formData.append('showAt', showAt);
    formData.append('link', (link || ''));
    formData.append('file', (file || ''));
    formData.append('start_from', (start_from ? (moment(start_from).format('YYYY-MM-DD')) : '' || ''));
    formData.append('end_at', (end_at ? (moment(end_at).format('YYYY-MM-DD')) : '' || ''));
    return formData
}

async function addAdvertise({title,type,showAt,link,file,start_from,end_at}){
    const formData=createFormData({title,type,showAt,link,file,start_from,end_at})
    return http.post(`${apiConfigration.advertisement.api}`,formData)
}

export function useAddAdvertiseMutation(){
    return useMutation('addAdvertise',addAdvertise)
}

async function editAdvertise({_id,title,type,showAt,link,file,start_from,end_at}){
    const formData=createFormData({title,type,showAt,link,file,start_from,end_at})
    return http.post(`${apiConfigration.advertisement.api}/${_id}`,formData)
}

export function useEditAdvertiseMutation(){
    return useMutation('editAdvertise',editAdvertise)
}

async function deleteAnnouncemnt({id}){
    return http.get(`${apiConfigration.advertisement.remove}/${id}`)
}

export function useDeleteAnnouncemntsMuatation(){
    return useMutation('deleteAnnouncemnt',deleteAnnouncemnt)
}