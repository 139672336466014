import http from "../util/http";
import { useMutation,useQuery } from "react-query";
import { apiConfigration ,getPagination} from "../config/api";

export const dimensionGender = ['all', 'male', 'female'];
export const language = ['all', 'english'];
export const avatarSize = ['Mini', 'Micro', 'Normal'];
export const type = ['Standard', 'Public', 'Private'];
export const playerMembershipLevel = ['all', 'Basic', 'VIP'];

async function getRegion({queryKey}){
    const {limit,pageIndex,search} = queryKey[1]
    const {pageSize} = getPagination()
    const { data } = await http.get(apiConfigration.regions.api+`?search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)
    return data;
}

export function useFetchRegionQuery(options){
    return useQuery(
        ['Region', options],
        getRegion
      )
}

async function addEditRegion(input){
    const {id}=input
    if(id){
        return http.post(`${apiConfigration.regions.api}/${id}`, input)
    }
    return http.post(`${apiConfigration.regions.api}`, input)
}
export function useAddEmailRegionMutation(){
    return useMutation('addEditRegion',addEditRegion)
}

async function getDimensions({queryKey}){
    const {limit,pageIndex,search,roomsConfigId} = queryKey[1]
    const {pageSize} = getPagination() 

    const { data } =await http.get(apiConfigration.dimensionConfig.api+`?roomsConfig_id=${roomsConfigId}&search=${search}&pageIndex=${(pageIndex)}&limit=${limit}&skip=${(pageIndex) * pageSize}`)

    return data;
}

export function useFetchDimensionsQuery(options){
    return useQuery(
        ['getDimensions', options],
        getDimensions
      )
}

async function addEditDimension(input){
    const {id}=input
    if(id){
        return http.post(`${apiConfigration.dimensionConfig.api}/${id}`, input)
    }
    return http.post(`${apiConfigration.dimensionConfig.api}`, input)
}
export function useAddEditDimensionMutation(){
    return useMutation('addEditDimension',addEditDimension)
}