import React, {useState} from 'react'
import UserDetailLayout from '../../../layouts/UserDetailLayout'
import {
    useUserOverviewQuery,
    useAllBadgeQuery,
    useUserActivityQuery,
    useUpdateUserOverviewMutation,
    useAddUserBadgeMutation,
    useRemoveBadgeMutation
} from '../../../data/user-overview'
import {environment} from '../../../config/environment'
import {permissionList} from '../../../data/permission-list'
import {checkPermission} from '../../../util/helpers'
import {useSearchParams} from 'react-router-dom'
import {showToast, checkEmail, getValue, checkUserName} from '../../../util/helpers'
import moment from 'moment';
import Spinner from '../../../components/common/spinner'
import DeleteConfirm from '../../../components/users/deleteConfirm'
import {apiConfigration} from '../../../config/api';
import UserSuspendModel from './userSuspendModel'
import AddNote from './addNote'

const genderList = ['Male', 'Female'];

function getId() {
    const p = new URLSearchParams(window.location.search)
    return p.get('id')
}

export default function Overview() {
    const [selectedBadge, setSelectedBadge] = useState('')
    const [search, setSearch] = useState('')
    const [pageIndex, setPageIndex] = useState(0)
    const [limit, setLimit] = useState(25)
    const [skip, setSkip] = useState(0)
    const [loading, setLoading] = useState('')
    const [modelShow, setModelShow] = useState(false)
    const [modelShow2, setModelShow2] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const {data: userDetails, isLoading: fetchloading, refetch} = useUserOverviewQuery({id: getId()})
    const {data: badgesList, isLoading: fetchloading2, refetch: refetch2} = useAllBadgeQuery({id: getId()})
    const {data: activityList, isLoading: fetchloading3, refetch: refetch3} = useUserActivityQuery({
        search,
        pageIndex,
        limit,
        skip,
        id: getId()
    })

    const {mutate: updateUserMutate, isLoading: updateloading} = useUpdateUserOverviewMutation()
    const {mutate: addUserBadgeMutate, isLoading: updateloading2} = useAddUserBadgeMutation()
    const {mutate: removeUserBadgeMutate, isLoading: updateloading3} = useRemoveBadgeMutation()

    const [memberEdit, setMemberEdit] = useState({
        MemberName: false,
        ScreenName: false,
        Email: false,
        Password: false,
        DOB: false,
        Gender: false,
    })

    function setDefaultImage(event) {
        event.target.src = "/images/no-profile-image.jpg"
    }

    function cancelSuspension() {

    }

    function refetchAll() {
        refetch()
        refetch2()
        refetch3()
    }

    function saveUserData(id) {
        if (id === 'email') {
            if (checkEmail(getValue('email'))) {
                return showToast('Invalid Email', 'error')
            }
        }
        if (id === 'un') {
            if (checkUserName(getValue('un'))) {
                return showToast('Invalid Username', 'error')
            }
        }
        updateUserMutate({
                _id: getId(),
                email: id === 'email' ? getValue('email') : userDetails.email,
                un: id === 'un' ? getValue('un') : userDetails.un,
                sn: id === 'sn' ? getValue('sn') : userDetails.sn,
                dob: id === 'dob' ? getValue('dob') : moment(userDetails.dob).format('YYYY-MM-DD'),
                gender: id === 'gender' ? getValue('gender') : userDetails.gender,
            },
            {
                onSuccess: (result) => {
                    if (result.data.message === 'success') {
                        showToast('User Successfully updated!', 'success')
                        setMemberEdit({
                            MemberName: false,
                            ScreenName: false,
                            Email: false,
                            Password: false,
                            DOB: false,
                            Gender: false,
                        })

                        refetchAll()
                    } else {
                        showToast(result.data.message, 'error')
                    }
                },
                onError: (error) => {
                    console.log(error.message);
                }
            })

    }

    function addUserBadge() {
        addUserBadgeMutate({
                uid: userDetails._id,
                badgeid: selectedBadge,
                adminid: getId(),
                isPrimary: true,
            },
            {
                onSuccess: (result) => {
                    if (result.data.message === 'success') {
                        showToast('User Badge Successfully added', 'success')
                        refetchAll()
                    }
                }
            })
    }

    function removeUserBadge(item) {
        removeUserBadgeMutate(
            {
                id: item._id,
                adminid: getId(),
            },
            {
                onSuccess: (result) => {
                    if (result.data.message == 'success') {
                        setSelectedBadge('No Badge')
                        showToast('User Badge Successfully added', 'success')
                        refetchAll()
                    }
                }
            })
    }

    return (

        <UserDetailLayout>
            <Spinner
                loading={fetchloading || fetchloading2 || fetchloading || updateloading || updateloading2 || updateloading3 || loading}/>
            <div className="row">
                <div className="col-md-4 p-3">
                    <div>
                        <div className="header-title">
                            Member Info
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Status <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    userDetails?.isOnline == 1 && (
                                        <span className="badge bg-success">Online</span>
                                    )
                                }
                                {
                                    userDetails?.isOnline == 0 && (
                                        <span className="badge bg-danger">Offline</span>
                                    )
                                }
                            </div>
                        </div>

                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Member ID <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                <div className="d-flex align-items-center justify-content-end">
                                    {userDetails?._id}
                                    <img className="profile ml-2" src={environment.SOCKET_ENDPOINT + userDetails?.pp}
                                         alt=""
                                         onError={setDefaultImage}/>
                                </div>
                            </div>
                        </div>
                        {
                            (userDetails?.suspend_till && userDetails?.userStillSuspended) && (
                                <div className="row detail-grid">
                                    <div className="col-4 detail-grid-left"> Suspend Till <span> : </span></div>
                                    <div className="col-8 detail-grid-right">
                                        {userDetails?.suspend_till}
                                        <i className="fa fa-times btn-ghost-danger font-lg cursor"
                                           tooltip="Cancel Suspension"
                                           onClick={() => cancelSuspension()}></i>
                                    </div>
                                </div>
                            )
                        }
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Member Name <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    memberEdit.MemberName && (
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <input className="form-control" type="text" id="un"
                                                       defaultValue={userDetails?.un} placeholder="Enter member name"/>

                                                <button type="button" className="btn btn-danger mx-1 w-50"
                                                        onClick={() => saveUserData('un')}>
                                                    <i className="fa fa-user-times"></i> Save
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (!memberEdit.MemberName) && (
                                        <div className="d-flex align-items-center justify-content-end">
                                            {userDetails?.un}
                                            {
                                                checkPermission(permissionList.UpdateMember) && (
                                                    <i className="fa fa-pencil cursor fs-1rem ml-3"
                                                       onClick={() => setMemberEdit({
                                                           ...memberEdit,
                                                           MemberName: true
                                                       })}></i>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Screen Name <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    memberEdit.ScreenName && (
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <input className="form-control" type="text" id="sn"
                                                       defaultValue={userDetails?.sn} placeholder="Enter screen name"/>

                                                <button type="button" className="btn btn-danger mx-1 w-50"
                                                        onClick={() => saveUserData('sn')}>
                                                    <i className="fa fa-user-times"></i> Save
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (!memberEdit.ScreenName) && (
                                        <div className="d-flex align-items-center justify-content-end">
                                            {userDetails?.sn}
                                            {
                                                checkPermission(permissionList.UpdateMember)
                                                && (<i className="fa fa-pencil cursor fs-1rem ml-3"
                                                       onClick={() => setMemberEdit({
                                                           ...memberEdit,
                                                           ScreenName: true
                                                       })}></i>)
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Email <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    memberEdit.Email && (
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <input className="form-control" type="text" placeholder="Enter email"
                                                       id="email" defaultValue={userDetails?.email}/>

                                                <button type="button" className="btn btn-danger mx-1 w-50"
                                                        onClick={() => saveUserData('email')}>
                                                    <i className="fa fa-user-times"></i> Save
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    !memberEdit.Email && (
                                        <div className="d-flex align-items-center justify-content-end">
                                            {userDetails?.email}
                                            {
                                                (checkPermission(permissionList.UpdateMember)) && (
                                                    <i className="fa fa-pencil cursor fs-1rem ml-3"
                                                       onClick={() => setMemberEdit({...memberEdit, Email: true})}></i>
                                                )
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Email Validated <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                <i className={`cursor fs-1rem ${checkEmail(userDetails?.email) ? 'fa fa-times color-red' : 'fa fa-check color-green'}`}></i>
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Password <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    memberEdit.Password && (
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <input className="form-control" type="password"
                                                       placeholder="Enter Password" id="password"
                                                       defaultValue={userDetails?.password}/>

                                                <button type="button" className="btn btn-danger mx-1 w-50"
                                                        onClick={() => saveUserData('password')}>
                                                    <i className="fa fa-user-times"></i> Save
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (!memberEdit.Password) && (
                                        checkPermission(permissionList.UpdateMember) &&
                                        (
                                            <div>
                                                <i className="fa fa-pencil cursor fs-1rem"
                                                   onClick={() => setMemberEdit({...memberEdit, Password: true})}></i>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> DOB <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    memberEdit.DOB && (
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <input className="form-control" type="date" id="dob"
                                                       defaultValue={moment(userDetails?.dob).format('YYYY-MM-DD')}/>

                                                <button type="button" className="btn btn-danger mx-1 w-50"
                                                        onClick={() => saveUserData('dob')}>
                                                    <i className="fa fa-user-times"></i> Save
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    !memberEdit.DOB && (
                                        <div className="d-flex align-items-center justify-content-end">
                                            {moment(userDetails?.dob).format('YYYY-MM-DD')}
                                            {
                                                checkPermission(permissionList.UpdateMember) && (
                                                    <i onClick={() => setMemberEdit({...memberEdit, DOB: true})}
                                                       className="fa fa-pencil cursor fs-1rem ml-3"></i>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Gender <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {
                                    memberEdit.Gender && (
                                        <div className="d-flex align-items-center">
                                            <div className="col-form-label d-flex flex-wrap">
                                                {
                                                    genderList?.map((item, i) => {
                                                        return (
                                                            <div key={i} className="form-check mr-2">
                                                                <input className="form-check-input" type="radio"
                                                                       id="gender" name="typeradios" value={item}/>
                                                                <label
                                                                    className="form-check-label text-capitalize">{item}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <button onClick={() => saveUserData('gender')} type="button"
                                                    className="btn btn-danger mx-1 w-50">
                                                <i className="fa fa-user-times"></i> Save
                                            </button>
                                        </div>
                                    )
                                }
                                {
                                    !memberEdit.Gender && (
                                        <div className="d-flex align-items-center justify-content-end">
                                            {userDetails?.gender}
                                            {
                                                checkPermission(permissionList.UpdateMember) && (
                                                    <i onClick={() => setMemberEdit({...memberEdit, Gender: true})}
                                                       className="fa fa-pencil cursor fs-1rem ml-3"></i>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Membership Type <span> : </span></div>
                            <div className="col-8 detail-grid-right">
                                {userDetails?.membership}
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-4 detail-grid-left"> Membership Ends <span> : </span></div>
                            <div className="col-8 detail-grid-right"></div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="header-title"> Management Tools</div>
                        {
                            !userDetails?.userStillSuspended && (
                                <div className="row detail-grid">
                                    <div className="col-12">
                                        {
                                            checkPermission(permissionList.DeleteMember) && (
                                                <DeleteConfirm name="User"
                                                               url={`${apiConfigration.user.remove}/${searchParams.get('id')}`}
                                                               refetch={() => refetch()}
                                                               setLoading={(value) => setLoading(value)}/>
                                            )
                                        }

                                        <button type="button" className="btn btn-danger mx-1 my-1">
                                            <i className="fa fa-user-times"></i> Impersonate Profile
                                        </button>
                                        {
                                            checkPermission(permissionList.DeleteMember) && (
                                                <button type="button" className="btn btn-danger mx-1 my-1"
                                                        onClick={() => setModelShow(true)}>
                                                    <i className="fa fa-user-times"></i> Suspend Member
                                                </button>
                                            )
                                        }

                                        <button type="button" className="btn btn-danger mx-1 my-1">
                                            <i className="fa fa-user-times"></i> Impersonate Game
                                        </button>

                                        <a href={`${environment.webLobbyUrl}profile?memberId=${userDetails?._id}`}
                                           target="blank">
                                            <button type="button" className="btn btn-danger mx-1 my-1">
                                                <i className="fa fa-user-times"></i> View Profile
                                            </button>
                                        </a>

                                        <button type="button" className="btn btn-danger mx-1 my-1"
                                                onClick={() => setModelShow2(true)}>
                                            <i className="fa fa-user-times"></i> Custom Notes
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="col-md-4 p-3">
                    <div>
                        <div className="header-title"> World Info</div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Last Login Date <span> : </span></div>
                            <div className="col-6 detail-grid-right">
                                {userDetails?.ll ? moment(userDetails?.ll).format('YYYY-MM-DD HH:mm:ss:SSS').toUpperCase() : ''}
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Last Login IP <span> : </span></div>
                            <div className="col-6 detail-grid-right">
                                {userDetails?.ip}
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Signup IP <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Outfit Slots <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Total Days Logged <span> : </span></div>
                            <div className="col-6 detail-grid-right">
                                {userDetails?.number_of_active_days}
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Idle Time <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Session ID <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Session Started <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Session Length <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Region <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Dimension <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Instance <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="header-title"> Bank Info</div>

                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Total Booty <span> : </span></div>
                            <div className="col-6 detail-grid-right">
                                {userDetails?.booty}
                            </div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Total Booty Spend <span> : </span></div>
                            <div className="col-6 detail-grid-right"></div>
                        </div>
                        <div className="row detail-grid">
                            <div className="col-6 detail-grid-left"> Total Booty Points <span> : </span></div>
                            <div className="col-6 detail-grid-right">
                                {userDetails?.bootyPoints}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 p-3">
                    <div>
                        <div className="header-title"> Account Roles</div>
                        <div className="text-center py-3">
                            List of account roles assigned
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="header-title"> Profile Roles</div>
                        <div className="text-center py-3">
                            List of roles assigned
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="header-title"> Badges</div>
                        <div className="py-3">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="form-group">
                                        <label className="col-form-label font-weight-bold required">Badges</label>
                                        <select className="form-control"
                                                value={selectedBadge}
                                                onChange={(event) => setSelectedBadge(event.target.value)}>
                                            <option value="0">Please select badge</option>
                                            {
                                                badgesList?.map((item, i) => {
                                                    return (<option key={i} value={item._id}>
                                                        {item.name}
                                                    </option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 pt-2rem" style={{marginTop: '30px'}}>
                                    <label className="col-form-label font-weight-bold"></label>
                                    {
                                        checkPermission(permissionList.AddUserBadges) && (
                                            <button type="button" className="btn btn-danger mx-1 my-1"
                                                    onClick={() => addUserBadge()}>
                                                <i className="fa fa-user-times"></i> Add Badge
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            {/*{userDetails?.usersBadges?.length === 0 &&*/}



                            {/*}*/}
                            {
                                userDetails?.usersBadges?.map((item, i) => {
                                    return (
                                        <div className="row detail-grid mt-3" key={i}>

                                            <div className="col-lg-2 pr-0 text-center align-self-center">
                                                {
                                                    item?._id && checkPermission(permissionList.DeleteUserBadges)
                                                }
                                                <i className="fa fa-times cursor pr-2"
                                                   style={{color: 'red', fontSize: '1.2rem', verticalAlign: 'bottom'}}
                                                   onClick={() => removeUserBadge(item)}></i>

                                                {/* [style]="item?._id ? 'vertical-align: middle;transform: scale(1.2);' : 'margin-left: 1.8rem;vertical-align: middle;transform: scale(1.2);'" */}

                                                <input type="radio" name="badgeRadios" checked={i === 0}/>
                                            </div>

                                            <div className="col-lg-4 pl-0  detail-grid-left text-capitalize">
                                                {item?.badgeid?.name}
                                            </div>

                                            <div className="col-lg-6 detail-grid-right">
                                                <div style={{
                                                    height: '20px',
                                                    width: '30px',
                                                    background: item?.badgeid?.color
                                                }}></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="row detail-grid mt-3">

                                <div className="col-lg-2 pr-0 text-center align-self-center">
                                    <i className="fa fa-times cursor pr-2 disabled"
                                       style={{color: 'transparent', fontSize: '1.2rem', verticalAlign: 'bottom'}}
                                    />

                                    {/* [style]="item?._id ? 'vertical-align: middle;transform: scale(1.2);' : 'margin-left: 1.8rem;vertical-align: middle;transform: scale(1.2);'" */}

                                    <input type="radio" name="badgeRadios"/>
                                </div>

                                <div className="col-lg-4 pl-0  detail-grid-left text-capitalize">
                                    No Badge
                                </div>

                                <div className="col-lg-6 detail-grid-right">

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 p-3">
                    <div>
                        <div className="header-title"> Admin Log</div>
                        <div className="py-3">
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-striped table-responsive-md my-3">
                                        <thead>
                                        <tr>
                                            <th>Date / Time</th>
                                            <th>Admin Name</th>
                                            <th>Activity</th>
                                        </tr>
                                        </thead>
                                        {/* *ngIf="activityPagination.totalRecords > 0;else noData" */}
                                        <tbody>
                                        {
                                            activityList?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{moment(item?.createdAt).format('YYYY-MM-DD HH:mm:ss:SSS')}</td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.activity}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    modelShow && (
                        <UserSuspendModel show={modelShow} setShow={() => setModelShow()}/>
                    )
                }
                {
                    modelShow2 && (
                        <AddNote show={modelShow2} setShow={() => setModelShow2()}/>
                    )
                }

            </div>
        </UserDetailLayout>
    )
}
