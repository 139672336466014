import Cookies from "js-cookie";
import { toast } from "react-toastify";

export function isAuthenticated() {
    let token = Cookies.get("token");
    if(token){
        return true;
    }
    return false;
}

export function getAuthCredentials(){

    let token = Cookies.get("token");
    if(token){
        return  {token: token, permissions: null };
    }
    return {token: null, permissions: null }
}

export function setToken(token){
    return Cookies.set('token',token)
}
export function getUser(){
  return window.localStorage.getItem('user')?JSON.parse(window.localStorage.getItem('user')):[]
}

export function showToast(message,type){
    const settings={
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    if(type==='success'){
        return toast.success(message, settings);
    }else if(type==="error"){
        return toast.error(message, settings);
    }
}

export function checkPermission(permission){
    if (localStorage['user']) {
      var currentUser = JSON.parse(localStorage['user']);
    }
    if (currentUser && currentUser.access && currentUser.access.length > 0) {
      const isAccess = currentUser.access.find(m => m === permission);
      if (isAccess) {
        return true;
      }
      return false;
    }
    return false;
  }

  export function getBase64Encoded(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let base64;
      reader.onload = async () => {
        base64 = reader.result.toString().split(',')[1];
        resolve({ base64, fileType: 'data:' + file.type + ';base64,' });
      };
      reader.onerror = async (error) => {
        reject(error);
      };
    });
  }
  export function checkEmail(email) {
    const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    return !regex.test(email);
  }
  export function getValue(id){
    return document.querySelector('#'+id).value;
  }

  export function checkUserName(userName) {
    if (userName) {
      if (!new RegExp('^[a-zA-Z0-9_]*$').test(userName)) {
        return 'Member names can only contain A-Z a-z 1-0 and underscores.';
      } else if (!new RegExp('^.{4,15}$').test(userName)) {
        return 'Member names must be a minimum of 4 and maximum of 15 characters.';
      } else if (!new RegExp('^(?!.*__.*)[a-zA-Z0-9](?:[a-zA-Z0-9_-]*[a-zA-Z0-9])?$').test(userName)) {
        return 'Member names cannot start or end with underscores, or contain double underscores in succession.';
      } else if (!new RegExp('[a-zA-Z]').test(userName)) {
        return 'Member names must contain atleast 1 letter.';
      } else if (userName.replace(/[^0-9]/g, '').length > 4) {
        return 'Member names cannot contain more then 4 numbers.';
      } else {
        return '';
      }
    } else {
      return 'Please enter member name';
    }
  }
  export function mathFloor(data) {
    return Math.floor(data);
  }


  export function timeTransform(totalSeconds){
    let string = '';
    if (totalSeconds) {
      const totalDay = 86400;
      const totalHour = 3600;
      const totalMinute = 60;

      const daysout = Math.floor(totalSeconds / totalDay);
      const hoursout = Math.floor((totalSeconds - daysout * totalDay) / totalHour);
      const minutesout = Math.floor((totalSeconds - daysout * totalDay - hoursout * totalHour) / totalMinute);
      const secondsout = Math.floor(totalSeconds - daysout * totalDay - hoursout * totalHour - minutesout * totalMinute);

      if (daysout > 0) {
        string += daysout + ' Days ';
      }
      if (hoursout > 0) {
        string += hoursout + ' Hours ';
      }
      if (minutesout > 0) {
        string += minutesout + ' Minutes ';
      }
      if (secondsout > 0) {
        string += secondsout + ' Seconds ';
      }
    }

    return string;
  }

  export function checkPassword(password) {
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/);
    return !regex.test(password);
  }